export const FETCH_ONDEMAND_CLASSES_SUCCESS = "FETCH_ONDEMAND_CLASSES_SUCCESS";
export const FETCH_ONDEMAND_CLASSES_START = "FETCH_ONDEMAND_CLASSES_START";
export const RESET_PASSWORD_START = "RESET_PASSWORD_START";
export const RESET_SUBSCRIPTION_STATUS = "RESET_SUBSCRIPTION_STATUS";

export const FETCH_ONDEMAND_DEEPLINK_CLASSES_SUCCESS =
  "FETCH_ONDEMAND_DEEPLINK_CLASSES_SUCCESS";
export const FETCH_ONDEMAND_DEEPLINK_CLASSES_START =
  "FETCH_ONDEMAND_DEEPLINK_CLASSES_START";
export const FETCH_ONDEMAND_DEEPLINK_CLASSES_FAIL =
  "FETCH_ONDEMAND_DEEPLINK_CLASSES_FAIL";

export const FETCH_ONDEMAND_DEEPLINK_COLLECTION_SUCCESS =
  "FETCH_ONDEMAND_DEEPLINK_COLLECTION_SUCCESS";
export const FETCH_ONDEMAND_DEEPLINK_COLLECTION_START =
  "FETCH_ONDEMAND_DEEPLINK_CLASSES_START";
export const FETCH_ONDEMAND_DEEPLINK_COLLECTION_FAIL =
  "FETCH_ONDEMAND_DEEPLINK_COLLECTION_FAIL";

export const FETCH_ONDEMAND_CLASSES_FAIL = "FETCH_ONDEMAND_CLASSES_FAIL";
export const SELECT_ONDEMAND_CLASS = "SELECT_ONDEMAND_CLASS";
export const FETCH_INTENSITY = "FETCH_INTENSITY";
export const SEARCH_ONDEMAND_CLASS_SUCCESS = "SEARCH_ONDEMAND_CLASS_SUCCESS";
export const CATEGORY_SEARCH_ONDEMAND_CLASS_SUCCESS =
  "CATEGORY_SEARCH_ONDEMAND_CLASS_SUCCESS";
export const STORE_ONDEMAND_TRACKING = "STORE_ONDEMAND_TRACKING";
export const FETCH_USER_RECENT_ACTIVITY_START =
  "FETCH_USER_RECENT_ACTIVITY_START";
export const FETCH_USER_RECENT_ACTIVITY_FAIL =
  "FETCH_USER_RECENT_ACTIVITY_FAIL";
export const FETCH_USER_RECENT_ACTIVITY = "FETCH_USER_RECENT_ACTIVITY";
export const SEARCH_ONDEMAND_CLASS_RECENT_SUCCESS =
  "SEARCH_ONDEMAND_CLASS_RECENT_SUCCESS";
export const SEARCH_ONDEMAND_START = "SEARCH_ONDEMAND_START";
export const CATEGORY_SEARCH_ONDEMAND_START = "CATEGORY_SEARCH_ONDEMAND_START";
export const SEND_CONTACT_US_MAIL_SUCCESS = "SEND_CONTACT_US_MAIL_SUCCESS";
export const SEND_FORGOT_PASSWORD_MAIL_SUCCESS =
  "SEND_FORGOT_PASSWORD_MAIL_SUCCESS";
export const SEND_FORGOT_PASSWORD_MAIL_FAIL = "SEND_FORGOT_PASSWORD_MAIL_FAIL";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";
export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_ACCESSDENIED = "AUTH_ACCESSDENIED";

export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";
export const UPDATE_USER_START = "UPDATE_USER_START";

export const TEST = "TEST";

export const STORE_WORKOUT_RESULT = "STORE_WORKOUT_RESULT";
export const WORKOUT_RESULT_SUBMIT_SUCCESS = "WORKOUT_RESULT_SUBMIT_SUCCESS";
export const WORKOUT_RESULT_SUBMIT_START = "WORKOUT_RESULT_SUBMIT_START";
export const WORKOUT_RESULT_SUBMIT_FAIL = "WORKOUT_RESULT_SUBMIT_FAIL";

export const FETCH_USER_DETAIL_START = "FETCH_USER_DETAIL_START";
export const FETCH_USER_DETAIL_SUCCESS = "FETCH_USER_DETAIL_SUCCESS";
export const FETCH_USER_DETAIL_FAIL = "FETCH_USER_DETAIL_FAIL";

export const FETCH_PO_DATA_START = "FETCH_PO_DATA_START";
export const FETCH_PO_DATA_SUCCESS = "FETCH_PO_DATA_SUCCESS";
export const FETCH_PO_DATA_FAIL = "FETCH_PO_DATA_FAIL";

export const FETCH_USER_SUBSCRIPTION_DETAILS_START =
  "FETCH_USER_SUBSCRIPTION_DETAILS_START";
export const FETCH_USER_SUBSCRIPTION_DETAILS_SUCCESS =
  "FETCH_USER_SUBSCRIPTION_DETAILS_SUCCESS";
export const FETCH_USER_SUBSCRIPTION_DETAILS_END =
  "FETCH_USER_SUBSCRIPTION_DETAILS_END";

export const FETCH_USER_SUBSCRIPTION_SUCCESS =
  "FETCH_USER_SUBSCRIPTION_SUCCESS";
export const FETCH_USER_SUBSCRIPTION_START = "FETCH_USER_SUBSCRIPTION_START";
export const FETCH_USER_SUBSCRIPTION_END = "FETCH_USER_SUBSCRIPTION_END";
export const FETCH_USER_TOKEN_ERROR = "FETCH_USER_TOKEN_ERROR";

export const FETCH_SUBSCRIPTION_PRODUCT_START =
  "FETCH_SUBSCRIPTION_PRODUCT_START";
export const FETCH_USER_SUBSCRIPTION_FAIL = "FETCH_USER_SUBSCRIPTION_FAIL";
export const FETCH_USER_SUBSCRIPTION_DETAILS_FAIL =
  "FETCH_USER_SUBSCRIPTION_DETAILS_FAIL";
export const FETCH_USER_CONSENT_SUCCESS = "FETCH_USER_CONSENT_SUCCESS";
export const FETCH_USER_CONSENT_START = "FETCH_USER_CONSENT_START";
export const STORE_USER_CONSENT = "STORE_USER_CONSENT";
export const FETCH_CONSENT_SUCCESS = "FETCH_CONSENT_SUCCESS";

export const FETCH_SUBSCRIPTION_PRODUCT_SUCCESS =
  "FETCH_SUBSCRIPTION_PRODUCT_SUCCESS";
export const FETCH_SUBSCRIPTION_PRODUCT_FAIL =
  "FETCH_SUBSCRIPTION_PRODUCT_FAIL";

export const START_FREE_TRIAL_SUBSCRIPTION_START =
  "START_FREE_TRIAL_SUBSCRIPTION_START";
export const START_FREE_TRIAL_SUBSCRIPTION_SUCCESS =
  "START_FREE_TRIAL_SUBSCRIPTION_SUCCESS";
export const START_FREE_TRIAL_SUBSCRIPTION_FAIL =
  "START_FREE_TRIAL_SUBSCRIPTION_FAIL";

export const PURCHASE_SUBSCRIPTION_START = "PURCHASE_SUBSCRIPTION_START";
export const PURCHASE_SUBSCRIPTION_SUCCESS = "PURCHASE_SUBSCRIPTION_SUCCESS";
export const PURCHASE_SUBSCRIPTION_FAIL = "PURCHASE_SUBSCRIPTION_FAIL";

export const CANCEL_SUBSCRIPTION_START = "CANCEL_SUBSCRIPTION_START";
export const CANCEL_SUBSCRIPTION_SUCCESS = "CANCEL_SUBSCRIPTION_SUCCESS";
export const CANCEL_SUBSCRIPTION_FAIL = "CANCEL_SUBSCRIPTION_FAIL";

export const RESUME_SUBSCRIPTION_START = "RESUME_SUBSCRIPTION_START";
export const RESUME_SUBSCRIPTION_SUCCESS = "RESUME_SUBSCRIPTION_SUCCESS";
export const RESUME_SUBSCRIPTION_FAIL = "RESUME_SUBSCRIPTION_FAIL";

export const FETCH_SUBSCRIPTION_PORTAL_LINK_START = "FETCH_SUBSCRIPTION_PORTAL_LINK_START";
export const FETCH_SUBSCRIPTION_PORTAL_LINK_SUCCESS = "FETCH_SUBSCRIPTION_PORTAL_LINK_SUCCESS";
export const FETCH_SUBSCRIPTION_PORTAL_LINK_FAIL = "FETCH_SUBSCRIPTION_PORTAL_LINK_FAIL";

export const USER_SIGNUP_START = "USER_SIGNUP_START";
export const USER_SIGNUP_SUCCESS = "USER_SIGNUP_SUCCESS";
export const RESET_USER_CREATED_FLAG = "RESET_USER_CREATED_FLAG";
export const USER_SIGNUP_FAIL = "USER_SIGNUP_FAIL";
export const VERIFY_FREEACCESSCODE_SUCCESS = "VERIFY_FREEACCESSCODE_SUCCESS";
export const VERIFY_FREEACCESSCODE_FAIL = "VERIFY_FREEACCESSCODE_FAIL";
export const VERIFY_FREEACCESSCODE_START = "VERIFY_FREEACCESSCODE_START";
export const GET_FREE_SUBSCRIPTION_START = "GET_FREE_SUBSCRIPTION_START";
export const GET_FREE_SUBSCRIPTION_SUCCESS = "GET_FREE_SUBSCRIPTION_SUCCESS";
export const GET_FREE_SUBSCRIPTION_FAIL = "GET_FREE_SUBSCRIPTION_FAIL";
export const SEND_CLASSDETAILS_TO_LOCALYTICS =
  "SEND_CLASSDETAILS_TO_LOCALYTICS";
export const SEND_PLAY_DURATION_TO_lOCALYTICS =
  "SEND_PLAY_DURATION_TO_lOCALYTICS";
export const SEND_LIVE_STREAM_PLAY_DURATION_TO_lOCALYTICS =
  "SEND_LIVE_STREAM_PLAY_DURATION_TO_lOCALYTICS";
export const SEND_CATEGORY_TO_LOCALYTICS = "SEND_CATEGORY_TO_LOCALYTICS";
export const CLASS_LOGGED = "CLASS_LOGGED";
export const CLASS_LOGGED_OFF = "CLASS_LOGGED_OFF";
export const NOTIFY_USER = "NOTIFY_USER";
export const CLOSE_NOTIFICATION = "CLOSE_NOTIFICATION";
export const CLASS_STARTED = "CLASS_STARTED";
export const CLASS_CLOSED = "CLASS_CLOSED";
export const SET_NOTIFICATION_TEXTS = "SET_NOTIFICATION_TEXTS";
export const SHOW_PRESET_NOTIFICATION = "SHOW_PRESET_NOTIFICATION";
export const GET_SEARCH_METADATA = "GET_SEARCH_METADATA";
export const CLEAR_FAV_CALSS_DATA = "CLEAR_FAV_CALSS_DATA";
export const IS_FAV = "IS_FAV";
export const CLEAR_SEARCH_CALSS_DATA = "CLEAR_SEARCH_CALSS_DATA";
export const CLEAR_CATEGORY_SEARCH_CALSS_DATA =
  "CLEAR_CATEGORY_SEARCH_CALSS_DATA";

export const CLEAR_CALSS_DATA = "CLEAR_CALSS_DATA";
export const CLEAR_LIVESTREAM_RECORDED_DATA = "CLEAR_LIVESTREAM_RECORDED_DATA";

export const HANDLE_SEARCH_TAB = "HANDLE_SEARCH_TAB";
export const HANDLE_CAT_TAB = "HANDLE_CAT_TAB";
export const STORE_SEARCH_PARAMS = "STORE_SEARCH_PARAMS";
export const SEARCH_METADATA_START = "SEARCH_METADATA_START";
export const SEARCH_PAGINATION = "SEARCH_PAGINATION";
export const SKIPPED_CATEGORY_SEARCH_ONDEMAND_CLASS_SUCCESS =
  "SKIPPED_CATEGORY_SEARCH_ONDEMAND_CLASS_SUCCESS";
export const SKIPPED_SEARCH_ONDEMAND_CLASS_SUCCESS =
  "SKIPPED_SEARCH_ONDEMAND_CLASS_SUCCESS";
export const CHANGE_SKIPPED_STATE = "CHANGE_SKIPPED_STATE";
export const CATEGORY_PARAMS_SKIPPED = "CATEGORY_PARAMS_SKIPPED";
export const STORE_CLIENT_SETTINGS = "STORE_CLIENT_SETTINGS";
export const SAVE_PREV_PLAYER = "SAVE_PREV_PLAYER";

export const SAVE_SELECTED_DATE = "SAVE_SELECTED_DATE";

export const THIRD_PARTY_AUTH_START = "THIRD_PARTY_AUTH_START";
export const THIRD_PARTY_AUTH_SUCCESS = "THIRD_PARTY_AUTH_SUCCESS";
export const THIRD_PARTY_AUTH_FAIL = "THIRD_PARTY_AUTH_FAIL";

export const VERIFY_DISCOUNT_START = "VERIFY_DISCOUNT_START";
export const VERIFY_DISCOUNT_SUCCESS = "VERIFY_DISCOUNT_SUCCESS";
export const VERIFY_DISCOUNT_FAIL = "VERIFY_DISCOUNT_FAIL";

export const FETCH_LIVECONNECT_START = "FETCH_LIVECONNECT_START";
export const FETCH_LIVECONNECT_SUCCESS = "FETCH_LIVECONNECT_SUCCESS";
export const FETCH_LIVECONNECT_FAIL = "FETCH_LIVECONNECT_FAIL";

export const FETCH_LSCONNECTHOME_START = "FETCH_LSCONNECTHOME_START";
export const FETCH_LSCONNECTHOME_SUCCESS = "FETCH_LSCONNECTHOME_SUCCESS";
export const FETCH_LSCONNECTHOME_FAIL = "FETCH_LSCONNECTHOME_FAIL";

export const FETCH_LSCONNECTLIST_START = "FETCH_LSCONNECTLIST_START";
export const FETCH_LSCONNECTLIST_SUCCESS = "FETCH_LSCONNECTLIST_SUCCESS";
export const FETCH_LSCONNECTLIST_FAIL = "FETCH_LSCONNECTLIST_FAIL";

export const FETCH_RECORDEDCLASSES_START = "FETCH_RECORDEDCLASSES_START";
export const FETCH_RECORDEDCLASSES_SUCCESS = "FETCH_RECORDEDCLASSES_SUCCESS";
export const FETCH_RECORDEDCLASSES_FAIL = "FETCH_RECORDEDCLASSES_FAIL";

export const FETCH_LSVIDEODETAILS_START = "FETCH_LSVIDEODETAILS_START";
export const FETCH_LSVIDEODETAILS_SUCCESS = "FETCH_LSVIDEODETAILS_SUCCESS";
export const FETCH_LSVIDEODETAILS_FAIL = "FETCH_LSVIDEODETAILS_FAIL";

export const FETCH_STREAMINGLINK_START = "FETCH_STREAMINGLINK_START";
export const FETCH_STREAMINGLINK_SUCCESS = "FETCH_STREAMINGLINK_SUCCESS";
export const FETCH_STREAMINGLINK_FAIL = "FETCH_STREAMINGLINK_FAIL";

export const FETCH_RECORDEDEVENTDATA_START = "FETCH_RECORDEDEVENTDATA_START";
export const FETCH_RECORDEDEVENTDATA_SUCCESS =
  "FETCH_RECORDEDEVENTDATA_SUCCESS";
export const FETCH_RECORDEDEVENTDATA_FAIL = "FETCH_RECORDEDEVENTDATA_FAIL";

export const CONNECT_CLASS_CLICKED = "CONNECT_CLASS_CLICKED";

export const REMOVE_DISCOUNT_COUPON = "REMOVE_DISCOUNT_COUPON";

export const SET_FAV_LOCAL = "SET_FAV_LOCAL";

export const SAVE_DEEPLINK_PAYLOAD = "SAVE_DEEPLINK_PAYLOAD";
export const SET_FAV_UNFAV_LOCAL = "SET_FAV_UNFAV_LOCAL";
export const SET_FAV_UNFAV_LOCAL_COLLECTION = "SET_FAV_UNFAV_LOCAL_COLLECTION";

export const TENENT_CONFIG_LOADING_START = "TENENT_CONFIG_LOADING_START";
export const TENENT_CONFIG_LOADING_SUCCESS = "TENENT_CONFIG_LOADING_SUCCESS";
export const TENENT_CONFIG_LOADING_FAIL = "TENENT_CONFIG_LOADING_FAIL";

export const UPDATE_USER_MARKETING_DETAILS_START =
  "UPDATE_USER_MARKETING_DETAILS_START";
export const UPDATE_USER_MARKETING_DETAILS_SUCCESS =
  "UPDATE_USER_MARKETING_DETAILS_SUCCESS";
export const UPDATE_USER_MARKETING_DETAILS_FAIL =
  "UPDATE_USER_MARKETING_DETAILS_FAIL";

export const SHOW_POPUP_SSO = "SHOW_POPUP_SSO";

/// ------- channels ----- ///
export const FETCH_ALLCHANNELS_START = "FETCH_ALLCHANNELS_START";
export const FETCH_ALLCHANNELS_SUCCESS = "FETCH_ALLCHANNELS_SUCCESS";
export const FETCH_ALLCHANNELS_FAIL = "FETCH_ALLCHANNELS_FAIL";

export const FETCH_CLASSESFORCHANNEL_START = "FETCH_CLASSESFORCHANNEL_START";
export const FETCH_CLASSESFORCHANNEL_SUCCESS =
  "FETCH_CLASSESFORCHANNEL_SUCCESS";
export const FETCH_CLASSES_SUCCESS = "FETCH_CLASSES_SUCCESS";
export const FETCH_CLASSESFORCHANNEL_CLEAR = "FETCH_CLASSESFORCHANNEL_CLEAR";
export const FETCH_CLASSESFORCHANNEL_FAIL = "FETCH_CLASSESFORCHANNEL_FAIL";
export const VIDEO_ERROR = "VIDEO_ERROR";

export const FETCH_MORECLASSESFORCHANNEL_START =
  "FETCH_MORECLASSESFORCHANNEL_START";
export const FETCH_MORECLASSESFORCHANNEL_SUCCESS =
  "FETCH_MORECLASSESFORCHANNEL_SUCCESS";
export const FETCH_MORECLASSESFORCHANNEL_FAIL =
  "FETCH_MORECLASSESFORCHANNEL_FAIL";

/*COTD_Homepage */
export const FETCH_COTD_DATA_START = "FETCH_COTD_DATA_START";
export const FETCH_COTD_DATA_FAIL = "FETCH_COTD_DATA_FAIL";
export const FETCH_COTD_DATA_SUCCESS = "FETCH_COTD_DATA_SUCCESS";

/*Filter Pane*/
export const SET_SELECTED_FILTERS = "SET_SELECTED_FILTERS";
export const SET_FILTER_SKILL = "SET_FILTER_SKILL";
export const SET_FILTER_KEYWORDS = "SET_FILTER_KEYWORDS";
export const SET_FILTERED_SUB_CATEGORY = "SET_FILTERED_SUB_CATEGORY";
export const SET_FILTER_DURATION = "SET_FILTER_DURATION";
export const SET_FILTER_FOCUS_AREA = "SET_FILTER_FOCUS_AREA";
export const SET_FILTER_EQUIPMENTS_TYPES = "SET_FILTER_EQUIPMENTS_TYPES";
export const SET_FILTER_INTENSITY = "SET_FILTER_INTENSITY";
export const SET_CLASS_LANGUAGE = "SET_CLASS_LANGUAGE";
export const SET_FILTER_SORT = "SET_FILTER_SORT";
export const SET_FILTER_SEARCH_TEXT = "SET_FILTER_SEARCH_TEXT";
export const RESET_FILTERS = "RESET_FILTERS";
export const FILTER_BY_SEARCH = "FILTER_BY_SEARCH";
export const SET_FILTERED_CLASS_SEARCH_DATA = "SET_FILTERED_CLASS_SEARCH_DATA";
export const SET_FILTERED_TOP_KEYWORDS = "SET_FILTERED_TOP_KEYWORDS";
export const FILTER_BY_SEARCH_START = "FILTER_BY_SEARCH_START";
export const SET_PAGE_COUNT = "SET_PAGE_COUNT";
export const SET_PAGINATED_DATA = "SET_PAGINATED_DATA";
export const SET_TOP_KEYWORDS = "SET_TOP_KEYWORDS";
export const SET_CLASSES_SEARCH_DATA = "SET_CLASSES_SEARCH_DATA";
export const SET_TEMP_CLASS_SEARCH_DATA = "SET_TEMP_CLASS_SEARCH_DATA";
export const SET_MEDIA_TYPE = "SET_MEDIA_TYPE";
/*Filter FllOnDemand*/
export const SET_SELECTED_FILTERS_ONDEMAND = "SET_SELECTED_FILTERS_ONDEMAND";
export const SET_FILTER_SKILL_ONDEMAND = "SET_FILTER_SKILL_ONDEMAND_ONDEMAND";
export const SET_FILTER_KEYWORDS_ONDEMAND =
  "SET_FILTER_KEYWORDS_ONDEMAND_ONDEMAND";
export const SET_FILTERED_SUB_CATEGORY_ONDEMAND =
  "SET_FILTERED_SUB_CATEGORY_ONDEMAND_ONDEMAND";
export const SET_FILTER_DURATION_ONDEMAND =
  "SET_FILTER_DURATION_ONDEMAND_ONDEMAND";
export const SET_FILTER_FOCUS_AREA_ONDEMAND = "SET_FILTER_FOCUS_AREA_ONDEMAND";
export const SET_FILTER_EQUIPMENTS_TYPES_ONDEMAND =
  "SET_FILTER_EQUIPMENTS_TYPES_ONDEMAND";
export const SET_FILTER_INTENSITY_ONDEMAND = "SET_FILTER_INTENSITY_ONDEMAND";
export const SET_CLASS_LANGUAGE_ONDEMAND = "SET_CLASS_LANGUAGE_ONDEMAND";
export const SET_FILTER_SORT_ONDEMAND = "SET_FILTER_SORT_ONDEMAND";
export const SET_FILTER_SEARCH_TEXT_ONDEMAND =
  "SET_FILTER_SEARCH_TEXT_ONDEMAND";
export const RESET_FILTERS_ONDEMAND = "RESET_FILTERS_ONDEMAND";
export const FILTER_BY_SEARCH_ONDEMAND = "FILTER_BY_SEARCH_ONDEMAND";
export const SET_FILTERED_CLASS_SEARCH_DATA_ONDEMAND =
  "SET_FILTERED_CLASS_SEARCH_DATA_ONDEMAND";
export const SET_FILTERED_TOP_KEYWORDS_ONDEMAND =
  "SET_FILTERED_TOP_KEYWORDS_ONDEMAND";
export const FILTER_BY_SEARCH_START_ONDEMAND =
  "FILTER_BY_SEARCH_START_ONDEMAND";
export const SET_PAGE_COUNT_ONDEMAND = "SET_PAGE_COUNT_ONDEMAND";
export const SET_PAGINATED_DATA_ONDEMAND = "SET_PAGINATED_DATA_ONDEMAND";
export const SET_CLASSES_SEARCH_DATA_ONDEMAND =
  "SET_CLASSES_SEARCH_DATA_ONDEMAND";
export const SET_TEMP_CLASS_SEARCH_DATA_ONDEMAND =
  "SET_TEMP_CLASS_SEARCH_DATA_ONDEMAND";
export const SET_TOP_KEYWORDS_ONDEMAND = "SET_TOP_KEYWORDS_ONDEMAND";
export const SET_MEDIA_TYPE_ONDEMAND = "SET_MEDIA_TYPE_ONDEMAND";

export const GET_MORE_CLASSES = "GET_MORE_CLASSES";
export const GET_MORE_CLASSES_START = "GET_MORE_CLASSES_START";

export const GLOBAL_SEARCH_START = "GLOBAL_SEARCH_START";
export const GLOBAL_SEARCH_SET_QUERY = "GLOBAL_SEARCH_SET_QUERY";
export const GLOBAL_SEARCH_SHOW = "GLOBAL_SEARCH_SHOW";
export const GLOBAL_SEARCH_SET_RESULT = "GLOBAL_SEARCH_SET_RESULT";
export const GLOBAL_SEARCH_ERROR = "GLOBAL_SEARCH_ERROR";
export const GLOBAL_SEARCH_SUCCESS = "GLOBAL_SEARCH_SUCCESS";
export const GLOBAL_SEARCH_END = "GLOBAL_SEARCH_END";

export const FETCH_ONDEMAND_START = "FETCH_ONDEMAND_START";
export const FETCH_ONDEMAND_SUCCESS = "FETCH_ONDEMAND_SUCCESS";
export const FETCH_ONDEMAND_FAIL = "FETCH_ONDEMAND_FAIL";
export const FLL_FILTER_ONDEMAND_SUCCESS = "FLL_FILTER_ONDEMAND_SUCCESS";

export const SET_EMBEDDED_MODE = "SET_EMBEDDED_MODE";
export const SHOW_ERROR_PAGE_SSO = "SHOW_ERROR_PAGE_SSO";

export const SSO_LOGIN_FAIL = "SSO_LOGIN_FAIL";
export const SSO_LOGIN_SUCCESS = "SSO_LOGIN_SUCCESS";
export const SSO_LOGIN_START = "SSO_LOGIN_START";

export const RESET_FLL_ONDEMAND = "RESET_FLL_ONDEMAND";
export const FETCH_PLAYBACK_STREAMINGLINK_SUCCESS =
  "FETCH_PLAYBACK_STREAMINGLINK_SUCCESS";
export const PlayList_FETCH_PLAYBACK_STREAMINGLINK_SUCCESS =
  "PlayList_FETCH_PLAYBACK_STREAMINGLINK_SUCCESS";
export const NAVIGATED_FROM_WHICH_SCREEN = "NAVIGATED_FROM_WHICH_SCREEN";

export const LIVE_STREAM_STARTED = "LIVE_STREAM_STARTED";

export const SET_FAVOURITED_CLASSES = "SET_FAVOURITED_CLASSES";

export const GET_FAVOURITED_CLASSES = "GET_FAVOURITED_CLASSES";

export const RESET_STREAMING_LINK_URL = "RESET_STREAMING_LINK_URL";

export const LOAD_EVENT = "LOAD_EVENT";

/// ------- Dynamic deeplink ----- ///
export const FETCH_DYNAMIC_DEEPLINK_START = "FETCH_DYNAMIC_DEEPLINK_START";
export const FETCH_DYNAMIC_DEEPLINK_SUCCESS = "FETCH_DYNAMIC_DEEPLINK_SUCCESS";
export const FETCH_DYNAMIC_DEEPLINK_FAIL = "FETCH_DYNAMIC_DEEPLINK_FAIL";

export const PLAY_LIST_START = "PLAY_LIST_START";
export const PLAY_LIST_END = "PLAY_LIST_END";
export const PLAY_LIST_SUCCESS = "PLAY_LIST_SUCCESS";
export const PLAY_LIST_ERROR = "PLAY_LIST_ERROR";
export const PLAY_LIST_CREATED_SUCCESS = "PLAY_LIST_CREATED_SUCCESS";
export const PLAY_LIST_UPDATED_SUCCESS = "PLAY_LIST_UPDATED_SUCCESS";
export const PLAY_LIST_REORDER_SUCCESS = "PLAY_LIST_REORDER_SUCCESS";
export const PLAY_LIST_MSG_CLEAR = "PLAY_LIST_MSG_CLEAR";

export const PLAY_LIST_INFO_START = "PLAY_LIST_INFO_START";
export const PLAY_LIST_INFO_END = "PLAY_LIST_INFO_END";
export const PLAY_LIST_INFO_TITLE_SUCCESS = "PLAY_LIST_INFO_TITLE_SUCCESS";
export const PLAY_LIST_INFO_LIST_SUCCESS = "PLAY_LIST_INFO_LIST_SUCCESS";
export const PLAY_LIST_INFO_ERROR = "PLAY_LIST_INFO_ERROR";
export const PLAY_LIST_INFO_UPDATE = "PLAY_LIST_INFO_UPDATE";
export const PLAY_LIST_INFO_DELETE = "PLAY_LIST_INFO_DELETE";
export const PLAY_LIST_INFO_SHOW_MODAL = "PLAY_LIST_INFO_SHOW_MODAL";
export const PLAYER_ON_FULLSCREEN = "PLAYER_ON_FULLSCREEN";
///----- Reviews and Rating ----///
export const FETCH_USER_RATING_SUCCESS = "FETCH_USER_RATING_SUCCESS";
export const FETCH_ALL_USER_RATING_START = "FETCH_ALL_USER_RATING_START";
export const FETCH_ALL_USER_RATING_SUCCESS = "FETCH_ALL_USER_RATING_SUCCESS";
export const FETCH_USER_RATING_FAIL = "FETCH_USER_RATING_FAIL";
export const UPDATE_USER_RATING_FAIL = "UPDATE_USER_RATING_FAIL";
export const UPDATE_USER_RATING_SUCCESS = "UPDATE_USER_RATING_SUCCESS";

///----- Footer Links ----///
export const FETCH_SITE_CONFIG_SUCCESS = "FETCH_SITE_CONFIG_SUCCESS"
export const FETCH_SITE_CONFIG_FAIL = "FETCH_SITE_CONFIG_FAIL"
